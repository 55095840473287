import React from "react";
import styles from "./Svg.module.scss";

const TraceSvg = () => (
  <div className={styles.wrapper}>
    <svg width="435" height="600" xmlns="http://www.w3.org/2000/svg">
      <g>
        <rect
          fill="none"
          className={styles.canvas}
          height="480"
          width="432"
          y="-1"
          x="-1"
        />
      </g>
      <g className={styles.svg_wrapper}>
        <path
          className={styles.svg_1}
          d="m148.09092,528.72712l1.3636,-9.81813l4,-11.27272l2.54545,-13.81818l2.18182,-12.36364l-0.72727,-7.99999l2.54545,-1.81819l3.27273,-9.0909l6.54545,-12.72727l1.09091,-7.63637l2.54545,-1.45454l2.90909,-13.81818l6.54546,-18.90909l12.72727,-25.45454"
        />
        <path
          className={styles.svg_2}
          d="m301.90907,271.636265l14.09087,18.181862l13.090907,7.272726l62.545443,30.545449l10.545453,15.272724l0,13.454543l5.090908,19.272724l-0.727273,7.272726l7.636362,11.636361l-1.090909,6.181817l4.363636,8.363635l-1.454545,13.454543l9.81818,45.090901l3.272727,31.999994l-5.454544,22.54545l-4.363636,5.818181"
        />
        <path
          className={styles.svg_3}
          d="m234.63636,528.36348l9.72723,-80.7272l2.90909,-42.18181l34.90908,-96.36362l13.45455,-18.90909l6.18181,-9.0909c0,-3.15152 0,-6.30303 0,-9.45455"
        />
        <path
          className={styles.svg_4}
          d="m266.99999,529.45439l-14.6364,-37.09085l-7.63636,-31.27272l-0.36364,-14.54545"
        />
        <path
          className={styles.svg_5}
          d="m240.81817,307.63626l3.18178,7.27277l-2.90909,14.18182l1.09091,9.0909l-15.63636,49.81818"
        />
        <path
          className={styles.svg_6}
          d="m252.727515,337.729439l1.365018,32.727474l-2.72729,18.181934"
        />
        <path
          className={styles.svg_7}
          d="m269.5458,326.36573l-15.90782,-18.18194l22.72742,-18.18194l18.63649,-15.90919l0.90909,-6.81822"
        />
        <path
          className={styles.svg_8}
          d="m428.63773,286.36547l-37.27159,-10.00007l-37.72752,-11.36371l-20.90922,-10.00006l1.81819,-6.36368l13.63645,-3.63638l20.90923,-1.36365l10.90916,0"
        />
        <path
          className={styles.svg_9}
          d="m413.18309,388.63886l15.00146,-13.18192l-15.00146,13.18192z"
        />
        <path
          className={styles.svg_10}
          d="m0.45317,293.1837l79.54734,-9.09098l89.09148,-11.3637l34.54568,-5.90913"
        />
        <path
          className={styles.svg_11}
          d="m298.6369,249.54706l33.63795,-4.09095l-33.63795,4.09095z"
        />
        <path
          className={styles.svg_12}
          d="m0.90772,227.72873l193.63898,10.45461l-193.63898,-10.45461z"
        />
        <path
          className={styles.svg_13}
          d="m194.636365,383.999874l2.57647,-8.8957l1.908526,-15.93822l7.252399,-32.617754l0.76341,-14.455595l3.435347,-11.861001l30.154712,-12.231658"
        />
      </g>
      <text
        fontWeight="bold"
        transform="rotate(-11.307585716247559 216.45564270019528,127.99208831787107) matrix(0.09659519788196998,0,0,0.14245066180882987,135.00324945983846,256.55158402167797) "
        textAnchor="start"
        fontFamily="'Courier New', Courier, monospace"
        className={styles.svg_14}
        y="-835.530183"
        x="-1350.883258"
      >
        let today = new Beginning ( );
      </text>
    </svg>
  </div>
);

export default TraceSvg;
